import logo from "./logo.png";
import "./App.css";
import { useRef, useState } from "react";

function App() {
  const [link, setLink] = useState("https://www.notifund.gr/checkout/");

  const lname = useRef();
  const fname = useRef();
  const email = useRef();
  const phone = useRef();

  const createUrl = (e) => {
    e.preventDefault();
    setLink(
      `https://www.notifund.gr/checkout/?fname=${fname.current.value}&lname=${lname.current.value}&email=${email.current.value}&phone=${phone.current.value}`
    );
  };

  return (
    <div className="App">
      <main className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h3>Checkout Link Generator</h3>
        <form onSubmit={createUrl}>
          <div>
            <label>Όνομα</label>
            <input ref={fname}></input>
          </div>
          <div>
            <label>Επίθετο</label>
            <input ref={lname}></input>
          </div>
          <div>
            <label>Τηλέφωνο</label>
            <input ref={phone}></input>
          </div>
          <div>
            <label>Email</label>
            <input ref={email}></input>
          </div>
          <button type="submit">Δημιουργία </button>
        </form>
        <p className="link">{link}</p>
        <button
          onClick={() => {
            navigator.clipboard.writeText(link);
          }}
        >Copy me</button>
      </main>
    </div>
  );
}

export default App;
